body {
    margin: 0;
    padding: 0;
  }
  
  .header {
    font-size: calc(20px + 2vmin);
    line-height: 2;
    text-transform: capitalize;
  }
  
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  
  .timer {
    background-color: transparent;
    color: white;
    display: inline-block;
    margin-top: 25px;
    padding: 10px;
    text-align: center;
    width: 400px;
  }
  
  .col-4 {
    width: 25%;
    float: left;
  }
  
  .col-4:last-child .box {
    border-right-color: transparent;
  }
  
  .box p {
    font-size: calc(16px + 2vmin);
    margin: 0;
  }
  
  .text {
    font-size: 14px
  }

  .timer {
    display: flex;
}

.col-4 {
    display: flex;
    align-items: center;
}

.box {
    position: relative;
}

.colon {
    font-size: 48px;
    margin-left: 0.8rem;
}
  
/* Existing CSS */
@media (max-width: 600px) {
    .timer {
      flex-direction: column; /* Change flex-direction to column for smaller screens */
      width: auto; /* Remove fixed width */
      max-width: 100%; /* Ensure the timer doesn't overflow its container */
    }
  
    .col-4 {
      display: block;
      width: 100%; /* Set width to 100% for smaller screens */
      float: none; /* Disable floating */
      justify-content: center;
    }
  
    .box p {
      font-size: calc(14px + 2vmin); /* Adjust font size for smaller screens */
    }
  
    .colon {
      font-size: 36px; /* Adjust colon font size for smaller screens */
      margin-left: 0.5rem; /* Adjust colon margin for smaller screens */
      display: inline-block; /* Ensure the colon is treated as a block-level element */
      transform: rotate(90deg); /* Rotate the colon by 90 degrees */
    }
  }