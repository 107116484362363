  body {
    margin: 0;
    padding: 0;
  }
  
  .header {
    font-size: calc(20px + 2vmin);
    line-height: 2;
    text-transform: capitalize;
  }
  
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  
  .titleTtimer {
    background-color: transparent;
    color: white;
    display: inline-block;
    margin-top: 25px;
    padding: 10px;
    text-align: center;
    width: 400px;
  }
  
  .titleCol-4 {
    width: 25%;
    float: left;
  }
  
  .titleCol-4:last-child .box {
    border-right-color: transparent;
  }
  
  .titleBox p {
    font-size: calc(16px + 2vmin);
    margin: 0;
  }
  
  .text {
    font-size: 14px
  }

  .titleTtimer {
    display: flex;
    flex-direction: row;
    justify-self: start;
}

.titleCol-4 {
    display: flex;
    align-items: center;
}

.titleBox {
    position: relative;
}

.colon {
    font-size: 48px;
    margin-left: 0.8rem;
}


  




