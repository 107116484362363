@font-face {
    font-family: 'Big Shoulders Display Black';
    src: url('../Fonts/BigShouldersText-Black.ttf') format('truetype'); /* Adjust the path and font format */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Big Shoulders Display Bold';
    src: url('../Fonts/BigShouldersText-Bold.ttf') format('truetype'); /* Adjust the path and font format */
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
    font-family: 'Inter Bold';
    src: url('../Fonts/InterBold.otf') format('truetype'); /* Adjust the path and font format */
    font-weight: normal;
    font-style: normal;
  }
