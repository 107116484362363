*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .wrapper{
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: -8%;
  }
  .accordion{
    width: 1014px;
    height: 400px;
  }
  
  .item{
    Width:900px;
    background: white;
    border: 2px solid #8A8A8A;
    border-radius: 18px;
    margin-bottom: 15px;
    margin-left: 50px;
    padding: 15px 30px;
  }
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content{
    color: #8A8A8A;
    width: 80%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
    margin-top: 10px;
  }
  .dropdown{
    cursor: pointer;
    font-size: 20px;
  }
  .content.show{
    height: auto;
    max-height: 9999px;
    margin-top: 10px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
  }
  .heading{
    color: #174B58;
    font-weight: bolder;
    font-size: 30px;
    margin-top: 20px;
    margin-left: 170px;
  }

  