.file-uploader {
    border: 1px solid #C4C4C4;
    border-radius: 3.5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .file-uploader:hover{
    border: 1px solid black;
  }
  
  .file-div {
    display: flex;
    width: 100%;
    height: 55px;
  }

 
  .filename{
    text-transform: capitalize;
    color: #C8C8C8;
  }

  
  .file-div > button {
    justify-content: space-between;
    padding: 1rem;
  }
  
  /* .disabled {
    background-color: #e9ecef;
  } */
  
  /* .file-name {
    margin-left: 0.5rem;
  } */
  
  .attach-icon {
    width: 100%;
    color: #C8C8C8;
  }

  .attachment-file:hover{

    background-color: white;

  }

 
  