.navigation {
  position: relative;
  display: inline-block;
}

.navigation-content {
  display: none;
  position: absolute;
  top: 100%; /* Position the container below the menu item */
  background-color: #f1f1f1;
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation-content-register {
  display: none;
  position: absolute;
  top: 100%; /* Position the container below the menu item */
  background-color: #f1f1f1;
  min-width: 160px;
  right: 0;
  left: 0;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation a {
  color: black;
  padding: 12px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.navigation a:hover {
  background-color: #ddd;
  border-radius: 4px;
}

.navigation:hover .navigation-content  {
  display: block;
}

.navigation:hover .navigation-content-register  {
  display: block;
}
